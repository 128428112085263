import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import history from "../history";

import PrivateRoute from "../components/routes/PrivateRoute";
import NoAuthRoute from "../components/routes/NoAuthRoute";
import AdminRoute from "../components/routes/AdminRoute";

import SignIn from "./auth/signIn/SignIn";
import SignUp from "./auth/signUp/SignUp";
import SignUpSuccess from "./auth/signUp/SignUpSuccess";
import SignUpVerify from "./auth/signUp/SignUpVerify";
import ResetPassword from "./auth/resetPassword/ResetPassword";
import ResetPasswordConfirm from "./auth/resetPassword/ResetPasswordConfirm";
import ResetPasswordNew from "./auth/resetPassword/ResetPasswordNew";

import EventPage from "./pages/node/EventPage";
import VideoPage from "./pages/node/VideoPage";
import StaticPage from "./pages/static/StaticPage";
import SearchPage from "./pages/search/SearchPage";
import UserPage from "./pages/userPage/UserPage";
import EventPaymentSummary from "./pages/payments/EventPaymentSummary";
import VideoPaymentSummary from "./pages/payments/VideoPaymentSummary";
import GiftPaymentSummary from "./pages/payments/GiftPaymentSummary";
import GroupTicketPaymentSummary from "./pages/payments/GroupTicketPaymentSummary";
import PaymentPage from "./pages/payments/PaymentPage";
import GiftPaymentPage from "./pages/payments/GiftPaymentPage";

import AdminPanel from "./admin/AdminPanel";
import UserPageAdmin from "./admin/panels/users/UserPageAdmin";
import UsersList from "./admin/panels/users/lists/UsersList";

import Dashboard from "./dashboard/Dashboard";
import NoAuthDashbord from "./dashboard/NoAuthDashbord";
import ErrorPage from "./error/ErrorPage";
import Maintenance from "./maintenance/Maintenance";

import "./../index.scss";
import { withTranslation } from "react-i18next";
import NoAuthVideoPage from "./pages/node/NoAuthVideoPage";
import NoAuthEventPage from "./pages/node/NoAuthEventPage";
import SignInLink from "./auth/signIn/SignInLink";

class App extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div className="App">
        <Router history={history}>
          <Switch>
            <NoAuthRoute path="/logowanie" component={SignIn} />
            <NoAuthRoute path="/admin-login" component={SignIn} />
            <Route path="/przerwa-techniczna" component={Maintenance} />
            <NoAuthRoute path="/rejestracja" exact component={SignUp} />
            <NoAuthRoute path="/rejestracja/sukces" component={SignUpSuccess} />
            <Route path="/wylogowano" component={SignIn} />
            <Route path="/logowanie-link" component={SignInLink} />
            <NoAuthRoute
              path="/weryfikacja-konta"
              exact
              component={SignUpVerify}
            />
            <NoAuthRoute
              path="/resetowanie-hasla"
              exact
              component={ResetPassword}
            />
            <NoAuthRoute
              path="/resetowanie-hasla/informacja"
              exact
              component={ResetPasswordConfirm}
            />
            <NoAuthRoute
              path="/nowe-haslo"
              exact
              component={ResetPasswordNew}
            />
            <PrivateRoute path="/" exact component={Dashboard} home={true} />
            <PrivateRoute path="/konto" exact component={UserPage} />
            <AdminRoute path="/konto/:id" exact component={UserPageAdmin} />
            <AdminRoute path="/teapp-admin" exact component={AdminPanel} />
            <AdminRoute
              path="/teapp-admin/uzytkownicy"
              exact
              component={UsersList}
            />
            <Route path="/error" component={ErrorPage} />
            <PrivateRoute
              path="/platnosc/prezent"
              component={GiftPaymentPage}
            />
            <PrivateRoute
              path="/platnosc/:productType/:productId"
              component={PaymentPage}
            />
            <PrivateRoute
              path="/status-zamowienia/wydarzenie/:extOrderId"
              component={EventPaymentSummary}
            />
            <PrivateRoute
              path="/status-zamowienia/wideo/:extOrderId"
              component={VideoPaymentSummary}
            />
            <PrivateRoute
              path="/status-zamowienia/prezent/:extOrderId"
              component={GiftPaymentSummary}
            />
            <PrivateRoute
              path="/status-zamowienia/bilet-grupowy/:extOrderId"
              component={GroupTicketPaymentSummary}
            />
            <PrivateRoute path="/wydarzenie/:id" exact component={EventPage} />

            <PrivateRoute path="/szukaj" component={SearchPage} />
            <PrivateRoute path="/wideo/:id" exact component={VideoPage} />
            <Route
              path="/regulamin"
              render={(props) => <StaticPage {...props} slug="regulamin" />}
            />
            <Route
              path="/polityka"
              render={(props) => <StaticPage {...props} slug="polityka" />}
            />
            <Route
              path="/kontakt"
              render={(props) => <StaticPage {...props} slug="kontakt" />}
            />
            <NoAuthRoute
              path="/strona-glowna"
              exact
              component={NoAuthDashbord}
            />

            <NoAuthRoute
              path="/wideo/:id/publiczne"
              exact
              component={NoAuthVideoPage}
            />

            <NoAuthRoute
              path="/wydarzenie/:id/publiczne"
              exact
              component={NoAuthEventPage}
            />
            <Route path="/n/:slug" component={StaticPage} />
            <Route
              render={(props) => (
                <ErrorPage
                  {...props}
                  statusCode={404}
                  message={t("errorpage.404_2")}
                />
              )}
            />
          </Switch>
        </Router>
      </div>
    );
  }
}

export default withTranslation()(App);
