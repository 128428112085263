import React from "react";
import { Button } from "antd";
import { Link } from "react-router-dom";
import {
  getDatetimeRange,
  renderProductPrice,
} from "../../../helpers/productUtilities";
import { withTranslation } from "react-i18next";
import tc from "../../../helpers/translateContent";

class FestivalEventBlock extends React.Component {
  renderPaymentButton = () => {
    const { event, userRole, t } = this.props;

    const paymentProcessing =
      event.paymentStatus === "PENDING" ||
      event.paymentStatus === "WAITING_FOR_CONFIRMATION";

    const hasAccess =
      Number.parseFloat(event.event_price) === 0 ||
      event.paymentStatus === "COMPLETED" ||
      userRole === "admin" ||
      userRole === "premium";

    const { eventStatus } = event;

    if (paymentProcessing)
      return (
        <Button className="orange" disabled>
          {t("button.processing")}
        </Button>
      );

    if (eventStatus !== -1 && !hasAccess)
      return (
        <Link to={`/wydarzenie/${event.slug || event.id}`}>
          <Button className="black">{t("button.buy")}</Button>
        </Link>
      );

    switch (eventStatus) {
      case -1:
        return (
          <Link to={`/wydarzenie/${event.slug || event.id}`}>
            <Button className="red">{t("button.ended")}</Button>
          </Link>
        );
      case 0:
        return (
          <Link to={`/wydarzenie/${event.slug || event.id}`}>
            <Button className="blue">{t("button.soon")}</Button>
          </Link>
        );
      case 1:
        return (
          <Link to={`/wydarzenie/${event.slug || event.id}?ogladaj=true`}>
            <Button className="green">{t("button.watch")}</Button>
          </Link>
        );
      default:
        break;
    }
  };
  getTimeRange = (from, to) => {
    if (from !== to) return from + " — " + to;
    return from;
  };
  getDateRange = (from, to) => {
    if (from !== to) return from + " — " + to;
    return from;
  };
  render() {
    const { event, t, shortDate } = this.props;
    const datetimeRange = getDatetimeRange(
      event.date_from,
      event.date_to,
      event.time_from,
      event.time_to,
      event.full_day
    );
    const time =
      datetimeRange.from.format("HH:mm") === "00:00" &&
      datetimeRange.to.format("HH:mm") === "23:59"
        ? t("videodesc.full_day")
        : !shortDate && !event.hide_time_to
        ? this.getTimeRange(
            datetimeRange.from.format("H:mm"),
            datetimeRange.to.format("H:mm")
          )
        : datetimeRange.from.format("H:mm");

    const date = !shortDate
      ? this.getDateRange(
          datetimeRange.from.format("DD.MM.YYYY"),
          datetimeRange.to.format("DD.MM.YYYY")
        )
      : datetimeRange.from.format("DD.MM.YYYY");
    return (
      <div
        className={`festival-card ${
          event.eventStatus === -1 ? "finished" : ""
        }`}
      >
        <div className="event-image-container">
          <Link to={`/wydarzenie/${event.slug || event.id}`}>
            <div
              className="event-image"
              style={{
                backgroundImage: `url(${
                  event.thumb_link
                    ? event.thumb_link
                    : event.image_link
                    ? event.image_link
                    : null
                })`,
              }}
            />
          </Link>
        </div>
        <div className="event-details">
          <div className="event-title">
            <Link to={`/wydarzenie/${event.slug || event.id}`}>
              {tc(event.title, "title", event.translations)}
            </Link>
            <div
              className="event-desc"
              dangerouslySetInnerHTML={{
                __html: tc(
                  event.additional_desc,
                  "additional_desc",
                  event.videoTranslations
                ),
              }}
            />
          </div>
          <div className="event-summ">
            <div className="event-date">
              <div className="day">{date}</div>
              <div className="time">{time}</div>
            </div>
            <div className="event-payment">
              <div className="event-payment-status">
                {this.renderPaymentButton()}
              </div>
              <div className="event-price">
                {renderProductPrice(event.event_price, t, event.newPrice)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(FestivalEventBlock);
