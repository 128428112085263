import React from "react";
import moment from "moment";
import { Field, reduxForm, change } from "redux-form";
import { Alert, Input as AInput, DatePicker, Form, Select } from "antd";
import { MailOutlined } from "@ant-design/icons";
import Input from "../../form-components/Input";
import Button from "../../form-components/Button";
import "./EditUserForm.scss";
import { makeField } from "../../form-components/makeComponent";
import CustomSelect from "../../form-components/CustomSelect";
import { withTranslation } from "react-i18next";
import { fetchAddresses } from "../../../theme-variables";
import countries from "../../../helpers/countries";

class EditUserForm extends React.Component {
  state = {
    day: this.props.initialValues.birthday.format("DD") || null,
    month: this.props.initialValues.birthday.format("MM") || null,
    year: this.props.initialValues.birthday.format("YYYY") || null,
  };
  onSubmit = (formValues) => {
    this.props.onSubmit(formValues);
  };
  renderCountryCodeSelect = ({
    input,
    placeholder,
    meta,
    prefix,
    suffix,
    required,
    ...rest
  }) => {
    const error = meta.touched && meta.error !== null ? meta.error : null;
    return (
      <Form.Item
        className={`teapp-select-wrapper ${required ? "required" : ""}`}
        colon
        help={error ? this.props.t(error) : null}
        onBlur={() => {
          input.onBlur(input.value);
        }}
        validateStatus={error ? "error" : null}
        hasFeedback
        {...rest}
      >
        <Select
          allowClear
          showSearch
          placeholder={placeholder}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          {...input}
          dropdownMatchSelectWidth={false}
        >
          {countries.map((v) => (
            <Select.Option key={v.code} value={v.code}>
              {v.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  };
  changeBirthdayDate = (val, name) => {
    let newState = this.state;
    newState[name] = val;
    this.setState(newState);
    const birthday = `${newState.year}-${newState.month}-${newState.day}`;
    this.props.dispatch(change("editUserForm", "birthday", moment(birthday)));
  };
  render() {
    const { error } = this.props.updateUserState;
    const { t } = this.props;
    return (
      <form
        className="edit-user-form"
        onSubmit={this.props.handleSubmit(this.onSubmit)}
      >
        <Field
          name="firstname"
          component={Input}
          required={true}
          placeholder={t("form.placeholder.name")}
        />
        <Field
          name="lastname"
          component={Input}
          required={true}
          placeholder={t("form.placeholder.lastname")}
        />
        {this.props.role === "admin" ? (
          <Field
            name="email"
            component={Input}
            required={true}
            placeholder={t("form.placeholder.email")}
          />
        ) : null}
        <Field
          name="password"
          component={Input}
          type="password"
          placeholder={t("form.placeholder.actual_passwd")}
        />
        <Field
          name="newpassword"
          component={Input}
          type="password"
          placeholder={t("form.placeholder.new_passwd")}
        />
        {this.props.role === "admin" ? (
          <label className="role-select" htmlFor="selectUserRole">
            {this.props.syncErrors && this.props.syncErrors.role
              ? "ERROR"
              : null}
            <Field
              onChange={(e) => {
                this.props.change(
                  "role",
                  e.target.options[e.target.selectedIndex].text
                );
              }}
              id="selectUserRole"
              name="role"
              label="Rola użytkownika"
              component={CustomSelect}
            >
              <option value="standard">Standardowy</option>
              <option value="unverified">Niezweryfikowany</option>
              <option value="admin">Administrator</option>
            </Field>
          </label>
        ) : null}
        {fetchAddresses ? (
          <label>
            {t("form.label.address")}:
            <Field
              name="street"
              component={Input}
              required={true}
              placeholder={t("form.placeholder.street")}
            />
            <AInput.Group compact>
              <Field
                name="city"
                component={Input}
                style={{ width: "49%", marginRight: "2%" }}
                required={true}
                placeholder={t("form.placeholder.city")}
              />
              <Field
                name="state"
                component={Input}
                style={{ width: "49%" }}
                required={true}
                placeholder={t("form.placeholder.state")}
              />
            </AInput.Group>
            <AInput.Group compact>
              <Field
                name="postal_code"
                component={Input}
                style={{ width: "49%", marginRight: "2%" }}
                required={true}
                placeholder={t("form.placeholder.postal_code")}
              />
              <Field
                name="country_code"
                component={this.renderCountryCodeSelect}
                style={{ width: "49%" }}
                required={true}
                placeholder={t("form.placeholder.country_code")}
              />
            </AInput.Group>
          </label>
        ) : (
          <Field
            name="country_code"
            component={this.renderCountryCodeSelect}
            required={true}
            placeholder={t("form.placeholder.country_code")}
          />
        )}
        <label>
          {t("form.label.birthdate")}:
          <AInput.Group compact>
            <AInput
              placeholder={t("form.placeholder.dd")}
              maxLength={2}
              value={this.state.day}
              style={{ width: 45 }}
              onChange={(e) => {
                this.changeBirthdayDate(e.target.value, "day");
              }}
            />
            <AInput
              placeholder="–"
              style={{
                width: 8,
                border: "none",
                padding: "5.9px 0 0",
                background: "none",
              }}
              disabled
            />
            <AInput
              placeholder={t("form.placeholder.mm")}
              maxLength={2}
              value={this.state.month}
              style={{ width: 48 }}
              onChange={(e) => {
                this.changeBirthdayDate(e.target.value, "month");
              }}
            />
            <AInput
              placeholder="–"
              style={{
                width: 8,
                border: "none",
                padding: "5.9px 0 0",
                background: "none",
              }}
              disabled
            />
            <AInput
              placeholder={t("form.placeholder.yyyy")}
              maxLength={4}
              value={this.state.year}
              style={{ width: 66 }}
              onChange={(e) => {
                this.changeBirthdayDate(e.target.value, "year");
              }}
            />
          </AInput.Group>
        </label>
        <Field
          className="datePicker"
          name="birthday"
          component={makeField(DatePicker, "DD.MM.YYYY", true)}
          placeholder={t("form.label.birthdate")}
          hasFeedback
          onFocus={(e) => e.preventDefault()}
          onBlur={(e) => e.preventDefault()}
        />
        <br />
        {error ? (
          <>
            <Alert type="error" message={t(error)} showIcon />
            <br />
          </>
        ) : null}
        <Button type="primary" htmlType="submit" style={{ maxWidth: "300px" }}>
          {t("form.buttons.save")}
        </Button>
      </form>
    );
  }
}

const validate = (formValues) => {
  const errors = {};
  if (!formValues.firstname) errors.firstname = "form.validate.required";
  if (!formValues.lastname) errors.lastname = "form.validate.required";

  if (!/^[a-zA-ZzżźćńółęąśŻŹĆĄŚĘŁÓŃ\-—–\s]*$/.test(formValues.firstname))
    errors.firstname = "form.validate.special_chars";
  if (!/^[a-zA-ZzżźćńółęąśŻŹĆĄŚĘŁÓŃ\-—–\s]*$/.test(formValues.lastname))
    errors.lastname = "form.validate.special_chars";

  if (
    !/^[A-Za-zzżźćńółęąśŻŹĆĄŚĘŁÓŃ\d@^()_\-$#!%*?&]{8,}$/.test(
      formValues.newpassword
    )
  )
    errors.newpassword = "form.validate.passwd";

  if (formValues.birthday === null || !moment(formValues.birthday).isValid())
    errors.birthday = "form.validate.date";

  if (fetchAddresses) {
    if (!formValues.postal_code) errors.postal_code = "form.validate.required";
    if (!formValues.city) errors.city = "form.validate.required";
    if (!formValues.state) errors.state = "form.validate.required";
    if (!formValues.street) errors.street = "form.validate.required";
    if (
      formValues.postal_code &&
      (formValues.postal_code.length > 10 ||
        /<[a-z/][\s\S]*>/.test(formValues.postal_code))
    )
      errors.postal_code = "form.validate.wrong_input_max10";
    if (
      formValues.city &&
      (formValues.city.length > 30 || /<[a-z/][\s\S]*>/.test(formValues.city))
    )
      errors.city = "form.validate.wrong_input_max30";
    if (
      formValues.state &&
      (formValues.state.length > 30 || /<[a-z/][\s\S]*>/.test(formValues.state))
    )
      errors.state = "form.validate.wrong_input_max30";
  }
  if (!formValues.country_code) errors.country_code = "form.validate.required";
  if (
    formValues.country_code &&
    (formValues.country_code.length > 2 ||
      /<[a-z/][\s\S]*>/.test(formValues.country_code))
  )
    errors.country_code = "form.validate.wrong_input_max2";

  return errors;
};

export default reduxForm({ form: "editUserForm", validate })(
  withTranslation()(EditUserForm)
);
