import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Card, Button, Popconfirm, message } from "antd";
import { getUserOrders as getUserEventOrders } from "../../../actions/paymentsActions";
import { getUserOrders as getUserVideoOrders } from "../../../actions/videoPaymentsActions";
import { getUserOrders as getUserGiftOrders } from "../../../actions/giftsActions";
import { getUserOrders as getUserGroupTicketOrders } from "../../../actions/groupTicketsActions";
import {
  getUserCodes,
  activateCode,
  deleteUserCode,
} from "../../../actions/codesActions";
import {
  updateUser,
  deleteUser,
  clearUpdateUserState,
} from "../../../actions/authActions";
import Wrapper from "../../wrappers/Wrapper";
import EditUserForm from "./EditUserForm";
import UserMailingSettings from "./UserMailingSettings";
import UserCodes from "./UserCodes";
import UserPaymentList from "./UserPaymentList";
import { getDataSourceForUserPaymentList } from "../../../helpers/productUtilities";
import { withTranslation } from "react-i18next";
import { fetchAddresses } from "../../../theme-variables";
import qs from "query-string";
import UserGiftList from "./UserGiftList";
import UserGroupTicketList from "./UserGroupTicketList";

class UserPage extends React.Component {
  state = { edit: false, activated: false };
  onClick = () => {
    this.setState({ edit: !this.state.edit });
  };
  onSubmit = (formValues) => {
    this.props.updateUser(formValues);
    // this.setState({ edit: false });
  };
  componentDidMount() {
    this.props.getUserEventOrders();
    this.props.getUserVideoOrders();
    this.props.getUserGiftOrders();
    this.props.getUserGroupTicketOrders();
    this.props.getUserCodes(this.props.user.id);
    const edit = qs.parse(this.props.location.search).edit;
    if (edit) this.setState({ edit });
  }
  deleteAccount = () => {
    this.props.deleteUser();
  };
  deleteCode = (codeId) => {
    this.props.deleteUserCode(this.props.user.id, codeId);
  };
  activateCode = (codeId) => {
    this.props.activateCode(this.props.user.id, codeId);
    this.setState({ activated: true });
  };
  componentDidUpdate(prevProps, prevState) {
    const { t } = this.props;
    if (
      prevProps.userCodes !== this.props.userCodes &&
      this.props.userCodes.length > 0
    ) {
      if (
        this.props.activateSuccess !== null &&
        this.props.activateSuccess &&
        this.state.activated
      ) {
        this.setState({ activated: false });
        message.success(
          this.props.codeActivated
            ? t("userpage.code_used")
            : t("userpage.code_activated")
        );
      } else if (this.props.activateSuccess !== null && this.props.codeError)
        message.error(this.props.codeError);
    }
    if (
      prevProps.updateUserState.success !==
        this.props.updateUserState.success &&
      this.props.updateUserState.success &&
      prevState.edit
    ) {
      this.props.clearUpdateUserState();
      this.setState({ edit: false });
    }
  }
  render() {
    if (!this.props.user) {
      window.location.href = "/logowanie";
      return null;
    }
    const { t } = this.props;
    const {
      firstname,
      lastname,
      email,
      created_at,
      birthday,
      street,
      postal_code,
      country_code,
      city,
      state,
    } = this.props.user;
    const dataSource = getDataSourceForUserPaymentList(
      this.props.orders,
      this.props.videoOrders,
      this.props.giftOrders,
      this.props.groupTicketOrders,
      t
    );
    return (
      <Wrapper>
        <h1>
          {this.props.user.firstname} {this.props.user.lastname}
        </h1>
        <Card
          title={`${t("userpage.your_data")} - ${email}`}
          style={{ margin: "20px 0" }}
          extra={
            <Button
              type={this.state.edit ? "default" : "primary"}
              onClick={this.onClick}
            >
              {this.state.edit ? t("userpage.back") : t("userpage.edit")}
            </Button>
          }
        >
          {this.state.edit ? (
            <EditUserForm
              onSubmit={this.onSubmit}
              updateUserState={this.props.updateUserState}
              initialValues={{
                firstname,
                lastname,
                email,
                birthday: moment(birthday),
                street,
                postal_code,
                country_code: country_code || "PL",
                city,
                state,
              }}
            />
          ) : (
            <>
              <p key="firstname">
                <b>{t("userpage.name")}: </b>
                {firstname}
              </p>
              <p key="lastname">
                <b>{t("userpage.lastname")}: </b>
                {lastname}
              </p>
              <p key="email">
                <b>{t("userpage.email")}: </b>
                {email}
              </p>
              {fetchAddresses ? (
                street ? (
                  <p key="address">
                    <b>{t("userpage.address")}: </b>
                    <br />
                    {street}
                    <br />
                    {postal_code} {city}
                    <br />
                    {state}, {country_code}
                  </p>
                ) : (
                  <p key="address">
                    <b>{t("userpage.address")}: </b>
                    <br />
                    {t("userpage.no_address")}
                  </p>
                )
              ) : (
                <p key="address">
                  <b>{t("form.placeholder.country_code")}: </b>
                  {country_code || "PL"}
                </p>
              )}
              <p key="registration_date">
                <b>{t("userpage.registration_date")}: </b>
                {moment(created_at).format("H:mm, DD.MM.YYYY")}
              </p>
              <p key="birthday">
                <b>{t("userpage.birthday")}: </b>
                {moment(birthday).format("DD.MM.YYYY")}
              </p>
            </>
          )}
        </Card>
        <UserCodes
          user={this.props.user}
          activateCode={this.activateCode}
          userCodes={this.props.userCodes}
          deleteUserCode={this.deleteCode}
          codeError={this.props.codeError}
        />
        <UserMailingSettings user={this.props.user} token={this.props.token} />
        {this.props.giftOrders.length ? (
          <Card
            title={t("userpage.your_gifts")}
            style={{ marginBottom: "30px" }}
          >
            <UserGiftList orders={this.props.giftOrders} />
          </Card>
        ) : null}
        {this.props.groupTicketOrders.length ? (
          <Card
            title={t("userpage.your_group_tickets")}
            style={{ marginBottom: "30px" }}
          >
            <UserGroupTicketList orders={this.props.groupTicketOrders} />
          </Card>
        ) : null}
        <Card
          title={t("userpage.your_orders")}
          style={{ marginBottom: "30px" }}
        >
          <UserPaymentList dataSource={dataSource} />
        </Card>
        <Card
          key="account-deleting"
          title={t("userpage.delete_account")}
          style={{ marginBottom: "30px" }}
        >
          <p>{t("userpage.delete_warning")}</p>
          <Popconfirm
            title={t("userpage.delete_confirm")}
            onConfirm={() => this.deleteAccount()}
            okText={t("const.yes")}
            cancelText={t("const.no")}
          >
            <Button className="red">{t("userpage.delete_operation")}</Button>
          </Popconfirm>
        </Card>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    updateUserState: state.auth.updateUser,
    orders: state.payments.orders,
    videoOrders: state.videoPayments.orders,
    giftOrders: state.gifts.orders,
    groupTicketOrders: state.groupTickets.orders,
    userCodes: state.codes.userCodes,
    activateSuccess: state.codes.activateSuccess,
    codeActivated: state.codes.activated,
    codeError: state.codes.error,
  };
};

export default connect(mapStateToProps, {
  updateUser,
  getUserVideoOrders,
  getUserEventOrders,
  getUserGiftOrders,
  getUserGroupTicketOrders,
  deleteUser,
  getUserCodes,
  activateCode,
  deleteUserCode,
  clearUpdateUserState,
})(withTranslation()(UserPage));
