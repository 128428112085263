import { createBrowserHistory } from "history";

import ReactPixel from "react-facebook-pixel";
import TagManager from "react-gtm-module";
import { facebookPixel, googleTagManager } from "./theme-variables";

const history = createBrowserHistory();
history.listen((location) => {
  if (googleTagManager)
    TagManager.dataLayer({
      event: "pageView",
      location: location.pathname,
    });
  if (facebookPixel) ReactPixel.pageView();
});

export default history;
