import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Drawer, Skeleton } from "antd";
import moment from "moment";
import EventList from "./lists/EventList";
import EventForm from "./forms/EventForm";
import {
  getEvents,
  deleteEvent,
  addEvent,
  updateEvent,
} from "../../../../actions/eventsActions";
import "../managers.scss";
import EventsTranslationsModal from "./others/EventsTranslationsModal";
import slugify from "../../../../helpers/slugify";

class EventsManager extends React.Component {
  state = {
    visible: false,
    mode: "add",
    chosenEvent: null,
    success: true,
    showTranslations: false,
  };
  componentDidMount() {
    this.props.getEvents();
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.eventSuccess && prevState.success) return { visible: false };
    return null;
  }
  handleAdd = (formValues) => {
    this.setState({ success: true });
    this.props.addEvent(formValues);
  };
  handleEdit = (formValues) => {
    this.setState({ success: true });
    this.props.updateEvent(formValues, this.state.chosenEvent);
  };
  handleSearch = (props) => {
    this.props.getEvents(props);
  };
  manageTranslationsClick = (id) => {
    this.setState({
      chosenEvent: id,
      showTranslations: true,
    });
  };
  addEventClick = () => {
    this.setState({
      visible: true,
      mode: "add",
      chosenEvent: null,
      success: false,
    });
  };
  editEventClick = (id) => {
    this.setState({
      visible: true,
      mode: "edit",
      chosenEvent: id,
      success: false,
    });
  };
  deleteEventClick = (id) => {
    this.props.deleteEvent(id);
  };
  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  render() {
    const events = this.props.events;
    const loading = this.props.loading;
    let initialValues = this.state.chosenEvent
      ? _.pick(
          events.filter((v) => v.id === this.state.chosenEvent)[0],
          "title",
          "subtitle",
          "event_price",
          "date_from",
          "date_to",
          "time_from",
          "time_to",
          "full_day",
          "published",
          "video_id",
          "available_tickets",
          "is_festival",
          "dashboard_hidden",
          "vimeo_chat",
          "hide_time_to",
          "enable_tickets",
          "enable_codes",
          "slug",
          "share_teatrlink"
        )
      : {
          published: true,
          dashboard_hidden: false,
          vimeo_chat: null,
          enable_tickets: true,
          enable_codes: false,
          share_teatrlink: true,
        };

    if (!initialValues.slug && initialValues.title)
      initialValues.slug = slugify(initialValues.title).substr(0, 40);

    if (initialValues.date_from && initialValues.date_to)
      initialValues.rangepicker = [
        moment(initialValues.date_from),
        moment(initialValues.date_to),
      ];
    if (initialValues.time_from && initialValues.time_to)
      initialValues.timerangepicker = [
        moment(initialValues.time_from, "HH:mm"),
        moment(initialValues.time_to, "HH:mm"),
      ];
    if (initialValues.full_day === 1) initialValues.full_day = true;
    if (initialValues.full_day === 0) initialValues.full_day = false;

    if (initialValues.enable_tickets === 0)
      initialValues.enable_tickets = false;
    if (initialValues.enable_tickets === 1) initialValues.enable_tickets = true;
    initialValues.enable_tickets = !initialValues.enable_tickets;
    if (!loading)
      return (
        <>
          <EventList
            events={events}
            addEventClick={this.addEventClick}
            editEventClick={this.editEventClick}
            deleteEventClick={this.deleteEventClick}
            onSubmit={this.handleSearch}
            manageTranslationsClick={this.manageTranslationsClick}
            videos={this.props.videos}
          />
          <Drawer
            className="formDrawer"
            width="90%"
            placement="right"
            closable={true}
            onClose={this.onClose}
            visible={this.state.visible}
            maskClosable={false}
          >
            <h2>
              {this.state.mode === "add"
                ? "Dodaj wydarzenie"
                : "Edytuj wydarzenie"}
            </h2>
            {this.state.visible ? (
              <EventForm
                mode={this.state.mode}
                onSubmit={
                  this.state.mode === "add" ? this.handleAdd : this.handleEdit
                }
                videos={this.props.videos}
                eventId={this.state.chosenEvent}
                eventSlugs={this.props.events
                  .map((e) => ({ slug: e.slug, id: e.id }))
                  .filter((slug) => !!slug)}
                initialValues={initialValues}
                error={this.props.eventError}
              />
            ) : null}
          </Drawer>
          {this.state.showTranslations ? (
            <EventsTranslationsModal
              visible={this.state.showTranslations}
              event={_.pick(
                this.props.events.filter(
                  (e) => e.id === this.state.chosenEvent
                )[0],
                "id",
                "title",
                "subtitle"
              )}
              closeModal={() => this.setState({ showTranslations: false })}
            />
          ) : null}
        </>
      );
    else return <Skeleton />;
  }
}

const mapStateToProsp = (state) => {
  return {
    eventSuccess: state.events.success,
    eventError: state.events.error,
    events: state.events.events,
    videos: state.videos.videos,
    loading: state.videos.loading,
  };
};

export default connect(mapStateToProsp, {
  addEvent,
  updateEvent,
  getEvents,
  deleteEvent,
})(EventsManager);
