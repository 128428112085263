import React from "react";
import { connect } from "react-redux";
import { Button, message, Modal } from "antd";
import { getConfig } from "../../actions/appConfigActions";
import vod from "../../apis/vod";
import { withTranslation } from "react-i18next";

class AcceptTos extends React.Component {
  state = {
    modalVisible: true,
    loading: false,
    accepted: false,
    statusLoading: true,
  };
  acceptTosByUser = () => {
    this.setState({ loading: true });
    if (!this.props.user) return false;
    if (this.props.token)
      vod
        .post(
          `/api/users/${this.props.user.id}/accept-tos`,
          {},
          {
            headers: {
              "x-auth-token": this.props.token,
            },
          }
        )
        .then((res) => {
          this.setState({
            accepted: true,
            loading: false,
            modalVisible: false,
          });
        })
        .catch((err) => {
          if (err.response.status !== 400)
            message.error(
              `${this.props.t("const.error_occured")}: ${err}`
            );
        });
  };
  getTosStatus = () => {
    this.setState({ statusLoading: true });
    if (!this.props.user) return false;
    vod
      .get(`/api/users/${this.props.user.id}/accept-tos`, {
        headers: {
          "x-auth-token": this.props.token,
        },
      })
      .then((res) => {
        this.setState({
          accepted: res.data.accept_tos,
          statusLoading: false,
        });
      })
      .catch((err) => {
        if (err.response.status !== 400)
          message.error(
            `${this.props.t("const.error_occured")}: ${err}`
          );
      });
  };
  componentDidMount() {
    this.getTosStatus();
  }
  render() {
    const { t } = this.props;
    if (!this.state.accepted && !this.state.statusLoading)
      return (
        <Modal
          title={t("newreg.title")}
          visible={this.state.modalVisible}
          okText={t("button.accept")}
          closable={false}
          maskClosable={false}
          footer={[
            <Button
              key="submit"
              type="primary"
              loading={this.state.loading}
              onClick={this.acceptTosByUser}
            >
              {t("button.accept")}
            </Button>,
          ]}
        >
          <p>{t("newreg.text_1")}</p>
          <p>{t("newreg.text_2")}</p>
          <ul>
            <li>
              <a
                href={this.props.appConfig.policy_page}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("const.policy")}
              </a>
            </li>
            <li>
              <a
                href={this.props.appConfig.regulations_page}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("const.regulations")}
              </a>
            </li>
          </ul>
        </Modal>
      );
    else return null;
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    token: state.auth.token,
    appConfig: state.appConfig.config,
  };
};

export default connect(mapStateToProps, {
  getConfig,
})(withTranslation()(AcceptTos));
