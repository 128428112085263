import React from "react";
import { connect } from "react-redux";
import mixitup from "mixitup";
import moment from "moment";
import { getCategories } from "../../../actions/categoriesActions";
import VideoCard from "./VideoCard";
import { withTranslation } from "react-i18next";
import tc from "../../../helpers/translateContent";

class AvailableVideos extends React.Component {
  state = {};
  constructor(props) {
    super(props);
    this.setContainerRef = this.setContainerRef.bind(this);
  }

  setContainerRef(element) {
    this.containerElement = element;
  }
  componentDidMount() {
    this.props.getCategories();
  }
  componentDidUpdate() {
    if (this.props.allowFilters && this.containerElement) {
      this.mixerTimeout = setTimeout(() => {
        this.mixer = mixitup(this.containerElement, {
          classNames: {
            elementFilter: "filter",
          },
        });
      }, 1000);
    }
  }
  componentWillUnmount() {
    clearTimeout(this.mixerTimeout);
  }
  render() {
    let usedCategories = [];
    let mixItUpClasses = "";
    if (this.props.categories) {
      usedCategories = this.props.categories.filter((c) => c.videos.length > 0);
      mixItUpClasses = "mix ";
      usedCategories.forEach((c) => {
        mixItUpClasses += `category-${c.id} `;
      });
    }
    const { t } = this.props;
    if (this.props.videos.length)
      return (
        <>
          <div className="available-video-list" ref={this.setContainerRef}>
            {this.props.allowFilters && usedCategories.length > 0 ? (
              <div
                className={`filters-switcher ${mixItUpClasses}`}
                data-order="1"
              >
                <h3>{t("dashboard.filter_vod")}:</h3>
                <div className="filters">
                  {usedCategories.map((c) => {
                    return (
                      <button
                        key={c.id}
                        type="button"
                        data-filter={`.category-${c.id}`}
                      >
                        {tc(c.name, "name", c.translations)}
                      </button>
                    );
                  })}
                  <button
                    className="all active"
                    type="button"
                    data-filter="all"
                  >
                    {t("dashboard.filter_show_all")}
                  </button>
                </div>
              </div>
            ) : null}
            {this.props.videos
              ? this.props.videos.map((v) => {
                  if (
                    v.visible &&
                    !(v.end_date && moment(v.end_date).isBefore(moment())) &&
                    !v.dashboard_hidden
                  )
                    return (
                      <VideoCard
                        allowFilters={
                          this.props.allowFilters && usedCategories.length
                        }
                        key={v.id}
                        processedEvent={
                          this.props.paymentStatus.videoId
                            ? this.props.paymentStatus.videoId
                            : null
                        }
                        userRole={this.props.user.role || undefined}
                        loading={this.props.paymentStatus.loading}
                        video={v}
                      />
                    );
                  return null;
                })
              : null}
            <div className="gap"></div>
            <div className="gap"></div>
            <div className="gap"></div>
          </div>
        </>
      );
    else return <h2>{t("dashboard.new_videos_soon")}</h2>;
  }
}

const mapStateToProps = (state) => {
  return {
    paymentStatus: state.videoPayments,
    user: state.auth.user || {},
    categories: state.categories.categories,
  };
};

export default connect(mapStateToProps, { getCategories })(
  withTranslation()(AvailableVideos)
);
