import React from "react";
import { Link } from "react-router-dom";
import logo from "../../static/logo.svg";
import { useTranslation } from "react-i18next";
import { shortVodUrl, miniNavbarHorizontal } from "../../theme-variables";
import "./MiniNavbar.scss";

const MiniNavbar = (props) => {
  const { t } = useTranslation();
  return (
    <div className={`mini-header ${miniNavbarHorizontal ? "horizontal" : ""}`}>
      <Link to="/">
        <img src={logo} alt="Logo teatru" />
      </Link>
      <div className="mini-header-text">
        <h1>{props.title || t("signin.title")}</h1>
        {shortVodUrl ? <h2>{shortVodUrl}</h2> : null}
      </div>
    </div>
  );
};

export default MiniNavbar;
