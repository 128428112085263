import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import vod from "../../../../../apis/vod";
import {
  Card,
  message,
  Table,
  Tag,
  List,
  Button,
  Input,
  Checkbox,
  Popconfirm,
  Space,
} from "antd";
import Highlighter from "react-highlight-words";
import { StopOutlined, SearchOutlined, EditOutlined } from "@ant-design/icons";
import Column from "antd/lib/table/Column";
import PaymentModal from "../../payments/others/PaymentModal";

class EventSummary extends React.Component {
  state = {
    orders: [],
    userval: "",
    priceIncluded: false,
    orderStatus: "COMPLETED",
    searchText: "",
    searchedColumn: "",
    paymentModalVisible: false,
    chosenPaymentId: null,
    chosenPayment: null,
  };
  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Szukaj...`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 250, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 110 }}
          >
            Szukaj
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 110 }}
          >
            Wyczyść
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (v, r) => {
      if (dataIndex === "user") {
        if (
          r.firstname.toString().toLowerCase().includes(v.toLowerCase()) ||
          r.lastname.toString().toLowerCase().includes(v.toLowerCase()) ||
          r.email.toString().toLowerCase().includes(v.toLowerCase())
        )
          return true;
        else return "";
      }
      return r[dataIndex]
        ? r[dataIndex].toString().toLowerCase().includes(v.toLowerCase())
        : "";
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  fetchOrders = () => {
    vod
      .get(`/api/payments/event/${this.props.eventId}`, {
        headers: {
          "x-auth-token": this.props.token,
        },
      })
      .then((res) => {
        const orders = res.data.orders.map((o) => {
          o.key = o.id;
          return o;
        });
        this.setState({
          orders: orders,
          views: res.data.views,
        });
      })
      .catch((err) => {
        message.error("Wystąpił błąd: " + err);
      });
  };
  deleteEventPayment = (id) => {
    vod
      .delete(`/api/payments/${id}`, {
        headers: {
          "x-auth-token": this.props.token,
        },
      })
      .then((res) => {
        message.success("Płatność została usunięta");
        this.fetchOrders();
      })
      .catch((err) => {
        message.error("Wystąpił błąd przy usuwaniu płatności");
      });
  };
  countStatistics() {
    const { orders, views } = this.state;
    let statistics = [
      {
        key: "Liczba unikalnych wyświetleń",
        value: views,
      },
      {
        key: "Liczba złożonych zamówień",
        value: orders.length,
      },
      {
        key: "Liczba zrealizowanych zamówień",
        value: orders.filter((o) => o.status === "COMPLETED").length,
      },
      {
        key: "Liczba zrealizowanych, płatnych zamówień",
        value: orders.filter((o) => o.status === "COMPLETED" && o.amount > 0)
          .length,
      },
      {
        key: "Liczba wykorzystanych zamówień",
        value: orders.filter((o) => o.used === 1).length,
      },
      {
        key: "Suma wpływów",
        value:
          orders
            .map((o) => {
              if (o.status === "COMPLETED") return Number.parseFloat(o.amount);
              return 0;
            })
            .reduce((a, b) => a + b, 0) + " zł",
      },
    ];
    return statistics;
  }
  showPaymentModal = (extOrderId) => {
    this.getPayment(extOrderId);
    this.setState({
      chosenPaymentId: extOrderId,
      paymentModalVisible: true,
    });
  };
  hidePaymentModal = () => {
    this.setState({ chosenPaymentId: null, paymentModalVisible: false });
  };
  getPayment = (extOrderId) => {
    vod
      .get(`/api/payments/${extOrderId}`, {
        headers: {
          "x-auth-token": this.props.token,
        },
      })
      .then((res) => {
        this.setState({ chosenPayment: res.data.order });
      })
      .catch((err) => {
        message.error("Wystąpił błąd przy pobieraniu informacji o płatności");
      });
  };
  editPayment = (props) => {
    vod
      .put(`/api/payments/${this.state.chosenPaymentId}`, props, {
        headers: {
          "x-auth-token": this.props.token,
        },
      })
      .then((res) => {
        message.success("Płatność została zaktualizowana");
        this.setState({
          chosenPaymentId: null,
          chosenPayment: null,
          paymentModalVisible: false,
        });
        this.fetchOrders();
      })
      .catch((err) => {
        message.error("Wystąpił błąd przy edycji płatności");
      });
  };
  addPermission = () => {
    const props = {
      userval: this.state.userval,
      priceIncluded: this.state.priceIncluded,
    };
    vod
      .post(`/api/payments/free/${this.props.eventId}`, props, {
        headers: {
          "x-auth-token": this.props.token,
        },
      })
      .then((res) => {
        this.fetchOrders();
        message.success("Przyznano uprawnienie");
      })
      .catch((err) => {
        message.error("Wystąpił błąd: " + err.response.data.message);
      });
  };
  componentDidMount() {
    this.fetchOrders();
  }
  render() {
    let dataSource = [];
    switch (this.state.orderStatus) {
      case "COMPLETED":
        dataSource = this.state.orders.filter((o) => o.status === "COMPLETED");
        break;
      case "CANCELED":
        dataSource = this.state.orders.filter((o) => o.status === "CANCELED");
        break;
      case "REFUNDED":
        dataSource = this.state.orders.filter((o) => o.status === "REFUNDED");
        break;
      case "PENDING":
        dataSource = this.state.orders.filter(
          (o) =>
            o.status === "PENDING" || o.status === "WAITING_FOR_CONFIRMATION"
        );
        break;
      default:
        dataSource = this.state.orders;
    }
    return (
      <>
        <Card
          title="Strefa administratora | Zamówienia"
          style={{ marginBottom: "30px" }}
        >
          <List
            itemLayout="horizontal"
            size="small"
            dataSource={this.countStatistics()}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  title={
                    <p style={{ marginBottom: 0 }}>
                      <strong>{item.key}: </strong>
                      {item.value}
                    </p>
                  }
                />
              </List.Item>
            )}
          />
          <br />
          <label style={{ marginRight: 10 }}>Wybierz status zamówienia: </label>
          <select
            style={{ padding: "5px 10px" }}
            name="status"
            defaultValue="COMPLETED"
            onChange={(e) => {
              this.setState({ orderStatus: e.target.value });
            }}
          >
            <option value="">Wszystkie</option>
            <option value="COMPLETED">Zakończone</option>
            <option value="CANCELED">Anulowane</option>
            <option value="REFUNDED">Zwrócone</option>
            <option value="PENDING">Przetwarzane</option>
          </select>
          <br />
          <br />
          <Table dataSource={dataSource} pagination={{ defaultPageSize: 20 }}>
            <Column
              title="Użytkownik"
              dataIndex="user"
              key="user"
              width="250px"
              sorter={(a, b) => a.lastname.localeCompare(b.lastname)}
              {...this.getColumnSearchProps("user")}
              render={(v, r) => {
                return this.state.searchedColumn === "user" ? (
                  <Link to={`/konto/${r.user_id}`} style={{ color: "black" }}>
                    <Highlighter
                      highlightStyle={{
                        backgroundColor: "#ffc069",
                        padding: 0,
                      }}
                      searchWords={[this.state.searchText]}
                      autoEscape
                      textToHighlight={`${r.lastname} ${r.firstname} ${r.email}`}
                    />
                  </Link>
                ) : (
                  <Link to={`/konto/${r.user_id}`} style={{ color: "black" }}>
                    {r.firstname} {r.lastname}
                    <br />
                    {r.email}
                  </Link>
                );
              }}
            />
            <Column
              title="Kraj i adres IP"
              dataIndex="country"
              key="country"
              sorter={(a, b) => a.country.localeCompare(b.country)}
              {...this.getColumnSearchProps("country")}
              render={(v, r) => {
                return this.state.searchedColumn === "country" ? (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {r.country ? (
                      <>
                        <Highlighter
                          highlightStyle={{
                            backgroundColor: "#ffc069",
                            padding: 0,
                          }}
                          searchWords={[this.state.searchText]}
                          autoEscape
                          textToHighlight={r.country}
                        />
                        <img
                          alt={`Flaga ${r.country}`}
                          height="17"
                          style={{ margin: "0 5px" }}
                          src={`https://cdn.ipregistry.co/flags/twemoji/${r.country.toLowerCase()}.svg`}
                        />
                        ({r.customer_ip})
                      </>
                    ) : (
                      r.customer_ip
                    )}
                  </div>
                ) : (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {r.country ? (
                      <>
                        {r.country}{" "}
                        <img
                          alt={`Flaga ${r.country}`}
                          height="17"
                          style={{ margin: "0 5px" }}
                          src={`https://cdn.ipregistry.co/flags/twemoji/${r.country.toLowerCase()}.svg`}
                        />
                        ({r.customer_ip})
                      </>
                    ) : (
                      r.customer_ip
                    )}
                  </div>
                );
              }}
            />
            <Column
              title="Czy wykorzystano?"
              dataIndex="used"
              key="used"
              sorter={(a, b) => a.views - b.views}
              render={(v, r) => {
                return (
                  <>
                    <Tag color={r.used ? "green" : "volcano"}>
                      <strong>Liczb wyświetleń: </strong>
                      {r.views || 0}
                    </Tag>
                  </>
                );
              }}
            />
            <Column
              title="Nr zamówienia"
              dataIndex="extOrderId"
              key="extOrderId"
              sorter={(a, b) => a.extOrderId - b.extOrderId}
              {...this.getColumnSearchProps("extOrderId")}
            />
            <Column
              title="Nr zamówienia w PayU"
              dataIndex="orderId"
              key="orderId"
              sorter={(a, b) => a.orderId.localeCompare(b.orderId)}
              {...this.getColumnSearchProps("orderId")}
            />
            <Column
              title="Cena"
              dataIndex="amount"
              key="amount"
              sorter={(a, b) => a.amount - b.amount}
              render={(v) => `${v} zł`}
            />
            <Column
              title="Utworzono"
              dataIndex="created_at"
              key="created_at"
              sorter={(a, b) =>
                moment(a.created_at).unix() - moment(b.created_at).unix()
              }
              render={(v) => moment(v).format("HH:mm, DD.MM.YYYY")}
            />
            <Column
              title="Status"
              dataIndex="status"
              key="status"
              render={(v) => {
                switch (v) {
                  case "NOT_STARTED":
                    return (
                      <Tag color="geekblue" key="PENDING">
                        NIEZAKOŃCZONA
                      </Tag>
                    );
                  case "PENDING":
                  case "WAITING_FOR_CONFIRMATION":
                    return (
                      <Tag color="geekblue" key="PENDING">
                        PRZETWARZANIE
                      </Tag>
                    );
                  case "COMPLETED":
                    return (
                      <Tag color="green" key="COMPLETED">
                        ZAKOŃCZONE
                      </Tag>
                    );
                  case "CANCELED":
                    return (
                      <Tag color="volcano" key="CANCELED">
                        ANULOWANE
                      </Tag>
                    );
                  case "REFUNDED":
                    return (
                      <Tag color="purple" key="REFUNDED">
                        ZWRÓCONE
                      </Tag>
                    );
                  default:
                    return null;
                }
              }}
            />
            <Column
              title="Akcje"
              key="action"
              render={(v, r) => {
                return (
                  <>
                    {r.status !== "COMPLETED" &&
                    r.status !== "CANCELED" &&
                    r.status !== "REFUNDED" ? (
                      <Popconfirm
                        title={`Czy na pewno chcesz anulować płatność #${r.extOrderId} użytkownika: ${r.firstname} ${r.lastname}? Operacja jest nieodwracalna!`}
                        onConfirm={() => this.deleteEventPayment(r.id)}
                        okText="Tak"
                        cancelText="Nie"
                      >
                        <StopOutlined
                          key="delete"
                          style={{ fontSize: 20, margin: "0 10px" }}
                        />
                      </Popconfirm>
                    ) : null}
                    <Popconfirm
                      title={`Czy na pewno chcesz edytować płatność? Zmiany mogą doprowadzić do niespójności z systemem płatności!`}
                      onConfirm={() => this.showPaymentModal(r.extOrderId)}
                      okText="Tak"
                      cancelText="Nie"
                    >
                      <EditOutlined
                        key="edit"
                        style={{ fontSize: 20, margin: "0 10px" }}
                      />
                    </Popconfirm>
                  </>
                );
              }}
            />
          </Table>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <label htmlFor="userSearch">
              Podaj email użytkownika, a następnie wciśnij przycisk, aby
              przydzielić mu dostęp do wydarzenia za darmo.
              <br />
              <br />
              <Input
                id="userValue"
                type="text"
                name="userval"
                value={this.state.userval}
                onChange={(e) => this.setState({ userval: e.target.value })}
                style={{
                  maxWidth: "200px",
                  marginRight: "15px",
                  marginBottom: "15px",
                }}
              />
              <label htmlFor="priceIncluded" style={{ margin: "0 15px" }}>
                <Checkbox
                  id="priceIncluded"
                  type="checkbox"
                  name="priceincluded"
                  checked={this.state.priceIncluded}
                  style={{ marginRight: "5px" }}
                  onChange={(e) =>
                    this.setState({ priceIncluded: e.target.checked })
                  }
                />
                Uwzględnić cenę wydarzenia?
              </label>
            </label>
            <Popconfirm
              title={`Czy na pewno chcesz to zrobić? Po operacji nie będzie można usunąć zamówienia z poziomu panelu.`}
              onConfirm={this.addPermission}
              okText="Tak"
              cancelText="Nie"
            >
              <Button type="primary" htmlType="submit">
                Przyznaj dostęp
              </Button>
            </Popconfirm>
          </form>
        </Card>
        <PaymentModal
          onClose={this.hidePaymentModal}
          visible={this.state.paymentModalVisible}
          editPayment={this.editPayment}
          payment={this.state.chosenPayment}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
  };
};

export default connect(mapStateToProps)(EventSummary);
