import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import "./index.scss";
import App from "./components/App";
import { ConfigProvider } from "antd";
import plPL from "antd/es/locale/pl_PL";

import "./i18n";

import "./additional-styles.scss";
import {
  facebookPixel,
  googleAnalytics,
  googleTagManager,
} from "./theme-variables";
import ReactPixel from "react-facebook-pixel";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";

if (facebookPixel) {
  const advancedMatching = {};
  ReactPixel.init(facebookPixel, advancedMatching, {
    autoConfig: true,
    debug: true,
  });
  // if (!localStorage.getItem("policyCookie")) ReactPixel.revokeConsent();
  ReactPixel.pageView();
}

if (googleAnalytics) {
  ReactGA.initialize(googleAnalytics, {
    gaOptions: {},
  });
}

if (googleTagManager) {
  TagManager.initialize({ gtmId: googleTagManager });
  TagManager.dataLayer({
    event: "pageView",
    location: window.location.pathname,
  });
}

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={plPL}>
      <App />
    </ConfigProvider>
  </Provider>,
  document.getElementById("root")
);
