import React from "react";
import { useTranslation } from "react-i18next";

const FileInput = ({
  urlField,
  input,
  type,
  meta,
  label,
  id,
  handleImageUploadChange,
  deleteImageFromServer,
  setImageUrl,
  currentImage,
}) => {
  const { t } = useTranslation();
  return (
    <div style={{ marginBottom: 20 }}>
      <label>
        <b>{label}:</b>
      </label>
      <br />
      <input
        id={id}
        style={{ margin: "5px 0" }}
        name={input.name}
        type={type}
        accept="image/jpeg, image/png"
        onChange={(event) => handleImageUploadChange(event, input)}
      />
      <a
        style={{ fontSize: 20 }}
        onClick={async () => {
          input.onChange(undefined);
          document.querySelector(`#${input.name}`).value = "";
          document.querySelector(`.imagePreview-${input.name}`).innerHTML = "";
          if (deleteImageFromServer) await deleteImageFromServer(urlField);
          setImageUrl(undefined, urlField);
        }}
      >
        x
      </a>
      {meta && meta.invalid && meta.error && (
        <div style={{ color: "#ff4d4f", marginBottom: 10 }}>
          {t(meta.error)}
        </div>
      )}
      {currentImage ? (
        <div
          className={`imagePreview imagePreview-${input.name}`}
          style={{ display: "flex", alignItems: "center" }}
        >
          <img
            src={currentImage}
            width="80"
            alt="Podgląd zdjęcia"
            style={{ marginRight: "10px" }}
          />
          <div>
            {/\.jpeg|\.jpg|\.png/.test(currentImage)
              ? currentImage.substr(currentImage.lastIndexOf("/") + 1)
              : "nowe zdjęcie"}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default FileInput;
