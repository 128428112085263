import React from "react";
import { Card } from "antd";
import "../managers.scss";
import { updateDate, version } from "../../../../theme-variables";

class AboutVersions extends React.Component {
  render() {
    return (
      <>
        <Card
          style={{ marginBottom: "30px" }}
          title="Informacje o wersji"
          className="aboutVersionsContainer"
        >
          <h3>Twoja wersja oprogramowania – teapp VOD {version} </h3>
        </Card>
        <Card
          style={{ marginBottom: "30px" }}
          title="Historia wersji"
          className="aboutVersionsContainer"
        >
          <b>
            teapp VOD 1.10
            <br />
            (data publikacji wersji {updateDate})
          </b>
          <br />
          <br />
          Funkcjonalności:
          <ul>
            <li>
              Dwuetapowe logowanie dla administratorów – po zalogowaniu na adres
              email wysyłany jest link do logowania. Link jest aktywny przez 10
              minut.
            </li>
            <li>Inteligentne linki wydarzeń </li>
            <li>
              Zablokowanie możliwości zmiany adresu przez zwykłego użytkownika w
              panelu – możliwa zmiana tylko przez administratora systemu.
            </li>
            <li>
              Uprawnienia administratora można przydzielać tylko użytkownikom
              posiadającym adres e-mail z zaufanej domeny (najczęściej z tej
              samej domeny na której osadzony jest system).{" "}
            </li>
            <li>
              Integracja z Facebook Pixel (wysyłanie informacji o zdarzeniach)
            </li>
            <li>
              Integracja z Google Tag Manager (przesyłanie warstwy danych
              dotyczących treści VOD, wydarzeń oraz podsumowań płatności do GTM
            </li>
          </ul>
          <br />
          <br />
          <b>
            teapp VOD 1.9
            <br />
            (data publikacji wersji 11 lutego 2021)
          </b>
          <br />
          <br />
          Funkcjonalności:
          <ul>
            <li>
              Bilet prezent – możliwość zakupu biletu w formie kodu na dowolny
              produkt lub/i konkretne wydarzenie/wideo (w zależności od
              ustawień)
            </li>

            <li>
              Bilety grupowe – możliwość zakupu biletów grupowych (generowanie
              konkretnej liczby kodów dostępu). Administrator ma możliwość
              ustawienia zniżki przy zakupie grupowym, minimalnej i maksymalnej
              liczby biletów oraz posiada możliwość przyznawania darmowego kodu
              dla osoby zamawiającej.{" "}
            </li>

            <li>
              Faktury – możliwość zaznaczenia opcji "Potrzebuję fakturę" w
              momencie zakupu (użytkownik/widz). Faktury nie są automatycznie
              generowane. Funkcjonalność ma ułatwić instytucji zarządzania
              płatnościami. W panelu pojawiła się zakładka „Faktury”
              (administrator).
            </li>

            <li>
              Możliwość ustawienia ważności biletów dla konkretnego wideo w
              sekcji VOD. Funkcjonalność nadpisuje ustawienia globalne z
              zakładki Ograniczenia.
            </li>
            <li>
              Aktualizacja geolokalizacji i usprawnienie funkcjonowania
              ograniczeń globalnych.
            </li>
            <li>
              Rozbudowane filtrowanie użytkowników o pozycje: rola, kraj i
              subskrybent newslettera.
            </li>
            <li>
              Dodanie informacji o adresie IP użytkowników (widoczne dla
              administratorów).
            </li>
            <li>
              Pojawienie się zakładki „Pomoc” z – FAQ, Panelem zgłoszeń i
              Słownikiem pojęć.
            </li>
            <li>
              Dodatkowa strona przed dokonaniem płatności z podsumowaniem
              płatności i opcjami zakupowymi.{" "}
            </li>
            <li>
              Możliwość definiowania większej ilości pul wymaganych do
              zarejestrowania (dane adresowe).{" "}
            </li>
          </ul>
          <br />
          <br />
          <b>
            teapp VOD 1.8
            <br />
            (data publikacji wersji 14 stycznia 2021)
          </b>
          <br />
          <br />
          Funkcjonalności:
          <ul>
            <li>Dodawanie czatu z Vimeo do wydarzeń na żywo (livestream)</li>
            <li>
              Ukrywanie wydarzeń w sekcji repertuar oraz wideo w sekcji VOD na
              stronie głównej
            </li>
            <li>
              Blokowanie płatności (np. gdy spektakl zostanie odwołany) –
              dotyczy wydarzeń w repertuarze
            </li>
            <li>
              Możliwość odblokowania kodów rabatowych w przypadku zablokowanych
              płatności (wówczas dostęp do wydarzeń można uzyskać tylko przez
              aktywowanie kodu rabatowego 100% lub przez formularz pod
              podsumowaniem na stronie wydarzeń)
            </li>
            <li>
              Info paski (czerwony i czarny) – do wykorzystywania w sytuacjach
              awaryjnych (dostępne w sekcji powiadomienia)
            </li>
            <li>
              Ukrywanie dla zwykłego użytkownika godziny końcowej w repertuarze
              (opcja przy wydarzeniach w repertuarze)
            </li>
            <li>
              Ulepszone statystyki (możliwość filtrowania zakresów, eksport
              danych)
            </li>
            <li>
              Ograniczenie biletów na darmowe wydarzenia (podanie liczby
              dostępnych biletów przy edycji wydarzenia powoduje, że użytkownik
              musi wygenerować sobie bilet zanim zacznie oglądać, jeżeli liczba
              ta nie jest podana to jeżeli wydarzenie jest dostępne to można od
              razu przejść do oglądania)
            </li>
            <li>
              Możliwość ustawiania w zakładce ograniczenia czasu ważności
              biletów dla treści VOD (bez ograniczeń, 3h, 6h, 12h, 24h, 36h,
              48h, 72h) – po upływie czasu można kupić ponownie dostęp. Jeżeli
              zdefiniowano datę zakończenia udostępniania wideo i jest ona
              wcześniej niż czas zakończenia ważności biletu to użytkownik
              informowany jest o tym przy wciśnięciu przycisku Kup dostęp.
            </li>
            <li>
              Podtytuły dla wydarzeń i wideo (widoczne w sekcjach na głównej
              stronie)
            </li>
            <li>Eksportowanie list płatności i statystyk do formatu CSV</li>
            <li>
              Przekazywanie do PayU informacji o nazwie wydarzenia i dacie (w
              tytule i opisie)
            </li>
          </ul>
        </Card>
      </>
    );
  }
}

export default AboutVersions;
