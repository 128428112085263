import React from "react";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { Tag } from "antd";

export const getDatetimeWithTz = (date) => {
  const tz = moment.tz(date, "Europe/Warsaw").format("Z");
  return moment(
    moment(date).tz("Europe/Warsaw").format("YYYY-MM-DD HH:mm:ss") + " " + tz,
    "YYYY-MM-DD HH:mm:ss Z"
  );
};

export const getDatetime = (dateFrom, timeFrom) => {
  dateFrom = moment(dateFrom).tz("Europe/Warsaw").format("YYYY-MM-DD");
  const tz = moment.tz(dateFrom + " " + timeFrom, "Europe/Warsaw").format("Z");
  let from = moment(
    dateFrom + " " + timeFrom + " " + tz,
    "YYYY-MM-DD HH:mm:ss Z"
  );
  return from;
};

export const getDatetimeRange = (
  dateFrom,
  dateTo,
  timeFrom,
  timeTo,
  fullDay
) => {
  dateFrom = moment(dateFrom).tz("Europe/Warsaw").format("YYYY-MM-DD");
  dateTo = moment(dateTo).tz("Europe/Warsaw").format("YYYY-MM-DD");
  if (fullDay) {
    timeFrom = "00:00:00";
    timeTo = "23:59:59";
  }

  const tzFrom = moment
    .tz(dateFrom + " " + timeFrom, "Europe/Warsaw")
    .format("Z");
  const tzTo = moment.tz(dateTo + " " + timeTo, "Europe/Warsaw").format("Z");
  let from = moment(
    dateFrom + " " + timeFrom + " " + tzFrom,
    "YYYY-MM-DD HH:mm:ss Z"
  );
  let to = moment(dateTo + " " + timeTo + " " + tzTo, "YYYY-MM-DD HH:mm:ss Z");
  return {
    from,
    to,
  };
};

/**
 * return event status by date.
 * -1 - event ended
 * 0 - event not active now
 * 1 - event active
 * @param {number} a
 * @param {number} b
 * @returns {number}
 */
export const checkEventStatusByDate = (
  date_from,
  date_to,
  full_day = false,
  time_from = null,
  time_to = null
) => {
  const dateTo = date_to || date_from;
  const timeTo = time_to || time_from;
  const fullDay = full_day === 1 ? true : full_day === 0 ? false : full_day;

  const datetimeRange = getDatetimeRange(
    date_from,
    dateTo,
    time_from,
    timeTo,
    fullDay
  );
  const currentDate = moment();

  if (currentDate.isAfter(datetimeRange.to, "days")) return -1;
  if (
    currentDate.isSame(datetimeRange.to, "days") &&
    currentDate.isAfter(datetimeRange.to, "minutes")
  )
    return -1;
  if (
    !currentDate.isBetween(datetimeRange.from, datetimeRange.to, "days", "[]")
  )
    return 0;

  let fromTime = moment()
    .set("hours", datetimeRange.from.format("HH"))
    .set("minutes", datetimeRange.from.format("mm"));
  let toTime = moment()
    .set("hours", datetimeRange.to.format("HH"))
    .set("minutes", datetimeRange.to.format("mm"));

  if (moment.tz.guess(true) !== "Europe/Warsaw") {
    const datetimeInPl = moment().tz("Europe/Warsaw");
    if (moment().isAfter(datetimeInPl)) toTime = toTime.add(1, "days");
    else fromTime = fromTime.subtract(1, "days");
  }

  if (!currentDate.isBetween(fromTime, toTime)) return 0;
  return 1;
};

export const getEventTimeDescription = (
  full_day,
  date_from,
  date_to,
  time_from,
  time_to,
  t,
  hideTimeTo = false,
  raw = false
) => {
  const fullDay = full_day === 1 ? true : full_day === 0 ? false : full_day;
  const datetimeRange = getDatetimeRange(
    date_from,
    date_to,
    time_from,
    time_to,
    full_day
  );
  let dateFrom = moment(datetimeRange.from).format("DD.MM.YYYY");
  let dateTo = moment(datetimeRange.to).format("DD.MM.YYYY");
  const timeFrom = moment(datetimeRange.from).format("H:mm");
  const timeTo = moment(datetimeRange.to).format("H:mm");

  let timeDesc =
    timeFrom === "0:00" && timeTo === "23:59"
      ? t("videodesc.full_day")
      : `${t("videodesc.watch_from")} ${timeFrom} ${
          !hideTimeTo
            ? fullDay || (time_from == "00:00:00" && time_to == "23:59:59")
              ? ` ${datetimeRange.from.format("D.MM")} ${t(
                  "videodesc.to"
                )} ${datetimeRange.to.format("H:mm D.MM")}`
              : `${t("videodesc.to")} ${timeTo}`
            : ""
        }`;

  if (
    !fullDay &&
    moment.tz.guess(true) !== "Europe/Warsaw" &&
    moment(timeTo, "HH:mm:ss").diff(moment(timeFrom, "HH:mm:ss")) !=
      moment(time_to, "HH:mm:ss").diff(moment(time_from, "HH:mm:ss"))
  ) {
    const hoursDiff = moment(time_to, "H:mm")
      .add(1, "minute")
      .diff(moment(time_from, "H:mm"), "hours");

    if (moment(timeFrom, "H:mm").isAfter(moment(timeTo, "H:mm"))) {
      dateTo = moment(dateTo, "DD.MM.YYYY")
        .subtract(1, "days")
        .format("DD.MM.YYYY");
    }
    timeDesc = `${
      dateFrom !== dateTo
        ? t("videodesc.watch_everyday")
        : t("videodesc.watch_from")
    } ${timeFrom} ${
      !hideTimeTo
        ? ` ${t("videodesc.throw")} ${hoursDiff} ${t("videodesc.short_hours")}`
        : ""
    }`;
  }

  let dateDesc = dateFrom === dateTo ? dateFrom : `${dateFrom}–${dateTo}`;
  if (raw) {
    return { dateDesc, timeDesc };
  }
  return (
    <>
      {t("videodesc.available")} {dateDesc}
      <br />
      {timeDesc}
    </>
  );
};

export const getEventPaymentDescription = (
  {
    event_id,
    title,
    full_day,
    date_from,
    date_to,
    time_from,
    time_to,
    used_code,
    slug,
  },
  t,
  raw = false
) => {
  const fullDay = full_day === 1 ? true : full_day === 0 ? false : full_day;
  const datetimeRange = getDatetimeRange(
    date_from,
    date_to,
    time_from,
    time_to,
    fullDay
  );
  date_from = moment(datetimeRange.from).format("DD.MM.YYYY");
  date_to = moment(datetimeRange.to).format("DD.MM.YYYY");
  time_from = moment(datetimeRange.from).format("H:mm");
  time_to = moment(datetimeRange.to).format("H:mm");

  const timeDesc =
    fullDay || (time_from == "00:00:00" && time_to == "23:59:59")
      ? `${t("videodesc.full_day")}${
          moment.tz.guess() === "Europe/Warsaw" ? "" : " CEST"
        }`
      : `${t("videodesc.from")} ${time_from} ${t("videodesc.to")} ${time_to}`;
  const dateDesc =
    date_from === date_to ? date_from : `${date_from}–${date_to}`;

  if (raw)
    return `Bilet na wydarzenie: ${title || t("videodesc.deleted")}${
      title ? ` (${dateDesc}, ${timeDesc})` : ""
    } ${used_code ? `(kupon: ${used_code})` : ""}`;
  return (
    <Link
      to={event_id ? `/wydarzenie/${slug || event_id}` : "#"}
      style={{ color: "black" }}
    >
      {t("videodesc.event_ticket")} "{title}"
      <br />({dateDesc}, {timeDesc}){" "}
      {used_code ? (
        <>
          <br />({t("videodesc.coupon")}: {used_code})
        </>
      ) : null}
    </Link>
  );
};

export const getVideoPaymentDescription = (
  { video_id, title, used_code, expiration_date, slug },
  t,
  raw = false
) => {
  if (raw)
    return `Zakup dostępu do wideo: ${title} ${
      used_code ? `(kupon: ${used_code})` : ""
    } ${
      expiration_date && expiration_date !== "0000-00-00"
        ? `(dostęp do: ${moment(expiration_date).format("H:mm, DD.MM.YYYY")})`
        : ""
    }`;
  return (
    <Link
      to={video_id ? `/wideo/${slug || video_id}` : "#"}
      style={{ color: "black" }}
    >
      {t("videodesc.buy_access_video")} {title || t("videodesc.deleted")}{" "}
      {used_code ? `(${t("videodesc.coupon")}: ${used_code})` : ""}
      {expiration_date && expiration_date !== "0000-00-00"
        ? `(${t("paymentsumm.access_to")}: ${moment(expiration_date).format(
            "H:mm, DD.MM.YYYY"
          )})`
        : ""}
    </Link>
  );
};

export const renderProductPrice = (
  productPrice,
  t,
  discountPrice = undefined,
  shortPrice = true
) => {
  productPrice = parseFloat(productPrice);
  const prefix = shortPrice ? "" : `${t("videodesc.price")}: `;

  if (productPrice === 0.0) return t("videodesc.free_access");

  if (discountPrice !== undefined)
    return (
      <>
        {prefix}
        {parseFloat(discountPrice).toFixed(2)}{" "}
        <del>{productPrice.toFixed(2)}</del> zł
      </>
    );

  return `${prefix}${productPrice.toFixed(2)} zł`;
};

export const renderTagByPaymentStatus = (status, t) => {
  switch (status) {
    case "NOT_STARTED":
      return (
        <Tag color="geekblue" key="PENDING">
          {t("payments.unfinished_s")}
        </Tag>
      );
    case "PENDING":
    case "WAITING_FOR_CONFIRMATION":
      return (
        <Tag color="geekblue" key="PENDING">
          {t("payments.pending_s")}
        </Tag>
      );
    case "COMPLETED":
      return (
        <Tag color="green" key="COMPLETED">
          {t("payments.completed_s")}
        </Tag>
      );
    case "CANCELED":
      return (
        <Tag color="volcano" key="CANCELED">
          {t("payments.canceled_s")}
        </Tag>
      );
    case "REFUNDED":
      return (
        <Tag color="purple" key="REFUNDED">
          {t("payments.refunded")}
        </Tag>
      );
    default:
      return null;
  }
};

export const getPaymentStatus = (status) => {
  switch (status) {
    case "NOT_STARTED":
    case "UNDEFINED":
      return "Niezakończona";
    case "PENDING":
    case "WAITING_FOR_CONFIRMATION":
      return "Przetwarzana";
    case "COMPLETED":
      return "Zakończona";
    case "CANCELED":
      return "Anulowana";
    case "REFUNDED":
      return "Zwrócona";
    default:
      return null;
  }
};
export const getDataSourceForGroupTicketList = (giftOrders, t) => {
  const orders = giftOrders || [];
  const giftOrdersWithKeys = orders.map((o) => {
    o.key = "g" + o.id;
    o.productType = o.product_slug.includes("event") ? "wydarzenie" : "wideo";
    o.translatedStatus = getPaymentStatus(o.status);
    o.updatedDate = moment(o.updated_at).format("H:mm, DD.MM.YYYY");
    return o;
  });

  let dataSource = giftOrdersWithKeys;

  dataSource = dataSource.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );

  return dataSource;
};
export const getDataSourceForGiftList = (giftOrders, t) => {
  const orders = giftOrders || [];
  const giftOrdersWithKeys = orders.map((o) => {
    o.key = "g" + o.id;
    o.productType = o.product_slug.includes("event")
      ? "wydarzenie"
      : o.product_slug.includes("video")
      ? "wideo"
      : "dowolny produkt";
    o.translatedStatus = getPaymentStatus(o.status);
    o.updatedDate = moment(o.updated_at).format("H:mm, DD.MM.YYYY");
    return o;
  });

  let dataSource = giftOrdersWithKeys;

  dataSource = dataSource.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );

  return dataSource;
};

export const getDataSourceForPaymentList = (
  eventOrders,
  videoOrders,
  t,
  includeEventOrders = true,
  includeVideoOrders = true
) => {
  const eventOrdersWithKeys = eventOrders.map((o) => {
    o.key = "e" + o.id;
    o.type = "event";
    o.desc = getEventPaymentDescription(o, t, true);
    o.translatedStatus = getPaymentStatus(o.status);
    return o;
  });
  const videoOrdersWithKeys = videoOrders.map((o) => {
    o.key = "v" + o.id;
    o.type = "video";
    o.desc = getVideoPaymentDescription(o, t, true);
    o.translatedStatus = getPaymentStatus(o.status);
    return o;
  });

  let dataSource = [];
  if (includeEventOrders && includeVideoOrders)
    dataSource = eventOrdersWithKeys.concat(videoOrdersWithKeys);
  else if (includeVideoOrders) dataSource = videoOrdersWithKeys;
  else if (includeEventOrders) dataSource = eventOrdersWithKeys;

  dataSource = dataSource.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );

  return dataSource;
};

export const getDataSourceForUserPaymentList = (
  eventOrders,
  videoOrders,
  giftOrders,
  groupTicketOrders,
  t
) => {
  const eventOrdersWithKeys = eventOrders.map((o) => {
    o.key = "e" + o.id;
    o.type = "event";
    o.desc = getEventPaymentDescription(o, t, true);
    o.translatedStatus = getPaymentStatus(o.status);
    return o;
  });
  const videoOrdersWithKeys = videoOrders.map((o) => {
    o.key = "v" + o.id;
    o.type = "video";
    o.desc = getVideoPaymentDescription(o, t, true);
    o.translatedStatus = getPaymentStatus(o.status);
    return o;
  });
  const giftOrdersWithKeys = giftOrders.map((o) => {
    o.key = "g" + o.id;
    o.type = "gift";
    o.desc = o.description;
    o.translatedStatus = getPaymentStatus(o.status);
    return o;
  });
  const groupTicketOrdersWithKeys = groupTicketOrders.map((o) => {
    o.key = "gt" + o.id;
    o.type = "groupTicket";
    o.desc = o.description;
    o.translatedStatus = getPaymentStatus(o.status);
    return o;
  });

  let dataSource = [].concat.apply(
    [],
    [
      videoOrdersWithKeys,
      eventOrdersWithKeys,
      giftOrdersWithKeys,
      groupTicketOrdersWithKeys,
    ]
  );

  dataSource = dataSource.sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at)
  );

  return dataSource;
};

export const getTimeToStart = (dateFrom, dateTo, timeFrom, timeTo, fullDay) => {
  fullDay = fullDay === 1 ? true : fullDay === 0 ? false : fullDay;
  const datetimeRange = getDatetimeRange(
    dateFrom,
    dateTo,
    timeFrom,
    timeTo,
    fullDay
  );
  const now = moment();
  if (now.isBefore(datetimeRange.from)) return datetimeRange.from;
  if (now.isAfter(datetimeRange.to)) return null;
  if (now.isBetween(datetimeRange.from, datetimeRange.to, "days", "[]")) {
    if (
      now
        .subtract(3, "seconds")
        .isBefore(
          moment(datetimeRange.from.format("HH:mm:ss"), "HH:mm:ss"),
          "minutes"
        )
    )
      return moment()
        .set("hours", datetimeRange.from.format("HH"))
        .set("minutes", datetimeRange.from.format("mm"))
        .set("seconds", 3)
        .set("miliseconds", 0);

    if (
      now.isAfter(
        moment(datetimeRange.to.format("HH:mm:ss"), "HH:mm:ss"),
        "minutes"
      )
    ) {
      if (now.isSame(datetimeRange.to, "day")) return null;
      else
        return moment(
          moment(
            moment().format("YYYY-MM-DD") +
              " " +
              datetimeRange.from.format("HH:mm")
          )
            .add(1, "days")
            .format()
        );
    }
  }
  return null;
};
