import i18n from "../i18n";
import { multipleLanguages } from "../theme-variables";

const tc = (text, slug, translations) => {
  const currentLanguage = i18n.language;
  if (!multipleLanguages || currentLanguage === "pl") return text;

  if (translations) {
    translations = translations.reduce((obj, item) => {
      obj[item.language_code] = item;
      return obj;
    }, {});
    if (
      translations[currentLanguage] &&
      translations[currentLanguage][slug]
    )
      return translations[currentLanguage][slug];
  }

  return text;
};

export default tc;
