import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import _ from "lodash";
import { Button, Skeleton, Card, Drawer, Modal, Popover } from "antd";
import {
  getCodes,
  getCode,
  deleteCode,
  updateCode,
  renderCodes,
} from "../../../../actions/codesActions";
import { getEvents } from "../../../../actions/eventsActions";
import { getVideos } from "../../../../actions/videosActions";
import CodeList from "./lists/CodeList";
import CodeFormWrapper from "./forms/CodeFormWrapper";
import CodeListFilter from "./lists/CodeListFilter";

class CodesManager extends React.Component {
  state = {
    visible: false,
    mode: "add",
    chosenCode: null,
    success: true,
    modalVisible: false,
  };
  onFilterChange = (props) => {
    this.props.getCodes(props);
  };
  getCodeUses = (codeId) => {
    this.props.getCode(codeId, { uses: true });
  };
  renderCodes = (formValues) => {
    this.setState({ success: true });
    this.props.renderCodes({
      ...formValues,
      expiration_date: formValues.expiration_date
        ? formValues.expiration_date.format("YYYY-MM-DD HH:mm")
        : null,
    });
  };
  updateCode = (formValues) => {
    this.setState({ success: true });
    this.props.updateCode(this.state.chosenCode, {
      ...formValues,
      expiration_date: formValues.expiration_date
        ? formValues.expiration_date.format("YYYY-MM-DD HH:mm")
        : null,
    });
  };
  renderCodesClick = () => {
    this.setState({
      visible: true,
      mode: "add",
      chosenCode: null,
      success: false,
    });
  };
  editCodeClick = (id) => {
    this.setState({
      visible: true,
      mode: "edit",
      chosenCode: id,
      success: false,
    });
  };
  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  closeModal = () => {
    this.setState({ modalVisible: false });
  };
  handleModalCopy = () => {
    const textarea = document.querySelector("#newCodes");
    textarea.select();
    document.execCommand("copy");
  };
  editCodeClick = (id) => {
    this.setState({
      visible: true,
      mode: "edit",
      chosenCode: id,
      success: false,
    });
  };
  componentDidMount() {
    this.props.getCodes({
      expiration_date_from: moment().format("YYYY-MM-DD"),
      available: true,
      min_limit: 2,
    });
    this.props.getVideos({ visible: true });
    this.props.getEvents();
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.newCodes !== this.props.newCodes &&
      this.props.newCodes.length > 0
    ) {
      this.setState({ modalVisible: true });
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.renderCodesSuccess &&
      prevState.success &&
      prevState.visible
    ) {
      nextProps.getCodes(nextProps.filterFormValues);
      return { visible: false, chosenCode: null };
    }
    return null;
  }
  render() {
    const { loading, codes } = this.props;
    let initialValues = {};
    if (this.state.mode === "edit" && this.state.chosenCode) {
      const chosenCode = codes.filter((c) => c.id === this.state.chosenCode)[0];
      if (chosenCode) {
        const productId = chosenCode.event_id
          ? `event-${chosenCode.event_id}`
          : chosenCode.video_id
          ? `video-${chosenCode.video_id}`
          : "any";
        initialValues = {
          ..._.pick(chosenCode, "code", "desc", "limit"),
          product_id: productId,
          discount:
            chosenCode.discount_cash ||
            `${Math.round(chosenCode.discount_percent)}%`,
          expiration_date: moment(chosenCode.expiration_date),
        };
      }
    }
    const newCodes = this.props.newCodes
      .map(({ code }) => {
        return code + "\n";
      })
      .join("");
    return (
      <>
        <Card
          title="Lista kuponów rabatowych"
          style={{ marginBottom: 30 }}
          extra={
            <Button type="primary" onClick={this.renderCodesClick}>
              Wygeneruj kody rabatowe
            </Button>
          }
          className="codes-manager"
        >
          <p>Domyślnie wyświetlane są dostępne kupony wielokrotnego użytku.</p>
          <CodeListFilter
            onFilterChange={this.onFilterChange}
            initialValues={{ available: true, min_limit: 2 }}
            videos={this.props.videos}
            events={this.props.events}
          />
          {!loading ? (
            <CodeList
              codes={codes.map((code) => {
                code.key = code.id;
                return code;
              })}
              deleteCode={this.props.deleteCode}
              editCodeClick={this.editCodeClick}
              getCodeUses={this.getCodeUses}
            />
          ) : (
            <Skeleton />
          )}
        </Card>
        <Drawer
          className="formDrawer codeFormDrawer"
          width="90%"
          placement="right"
          closable={true}
          onClose={this.onClose}
          visible={this.state.visible}
          maskClosable={false}
        >
          <h2>
            {this.state.mode === "add"
              ? "Wygeneruj kody rabatowe"
              : "Edytuj kod rabatowy"}
          </h2>
          {this.state.visible ? (
            <CodeFormWrapper
              mode={this.state.mode}
              updateCode={this.updateCode}
              renderCodes={this.renderCodes}
              initialValues={initialValues}
              events={this.props.events}
              videos={this.props.videos}
            />
          ) : null}
        </Drawer>
        <Modal
          visible={this.state.modalVisible}
          title="Wygenerowane kody:"
          onOk={this.closeModal}
          onCancel={this.closeModal}
          footer={[
            <Popover
              title="Skopiowano do schowka"
              trigger="click"
              key="copy-codes"
            >
              <Button key="back" onClick={this.handleModalCopy}>
                Kopiuj
              </Button>
            </Popover>,
            <Button key="submit" type="primary" onClick={this.closeModal}>
              Ok
            </Button>,
          ]}
        >
          <textarea value={newCodes} id="newCodes" readOnly />
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    renderCodesSuccess: state.codes.renderSuccess,
    codeError: state.codes.error,
    codes: state.codes.codes,
    newCodes: state.codes.newCodes,
    loading: state.codes.loading,
    videos: state.videos.videos,
    events: state.events.events,
    filterFormValues:
      state.form && state.form.codeListFilterForm
        ? state.form.codeListFilterForm.values
        : {},
  };
};

export default connect(mapStateToProps, {
  getCodes,
  getCode,
  deleteCode,
  renderCodes,
  updateCode,
  getEvents,
  getVideos,
})(CodesManager);
