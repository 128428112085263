import {
  GET_VIDEOS_SUCCESS,
  GET_VIDEOS_FAILURE,
  GET_VIDEO_SUCCESS,
  GET_VIDEO_FAILURE,
  VIDEOS_LOADING,
  DELETE_VIDEO_SUCCESS,
  DELETE_VIDEO_FAILURE,
  ADD_VIDEO_SUCCESS,
  ADD_VIDEO_FAILURE,
  UPDATE_VIDEO_SUCCESS,
  UPDATE_VIDEO_FAILURE,
} from "../actions/_types";

const initialState = {
  videos: [],
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_VIDEOS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        videos: action.payload,
      };
    case GET_VIDEOS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_VIDEO_SUCCESS:
      let found = false;
      let videos = state.videos.map((video) => {
        if (video.id === action.payload.id) {
          found = true;
          return action.payload;
        }
        return video;
      });
      if (!found) videos.push(action.payload);
      return {
        ...state,
        loading: false,
        error: undefined,
        videos: videos,
      };
    case GET_VIDEO_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case DELETE_VIDEO_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        videos: state.videos.filter(
          (video) => video.id !== action.payload
        ),
      };
    case DELETE_VIDEO_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
        time: Date.now(),
      };
    case ADD_VIDEO_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: undefined,
        videos: [action.payload, ...state.videos],
      };
    case ADD_VIDEO_FAILURE:
      return {
        ...state,
        success: false,
        error: action.payload,
        loading: false,
        time: Date.now(),
      };
    case UPDATE_VIDEO_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: undefined,
        videos: state.videos.map((video) => {
          if (video.id === action.payload.id) return action.payload;
          return video;
        }),
      };
    case UPDATE_VIDEO_FAILURE:
      return {
        ...state,
        success: false,
        error: action.payload,
        loading: false,
        time: Date.now(),
      };
    case VIDEOS_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
