import { reducer as formReducer } from "redux-form";
import {
  SIGNUP_FORM_ERRORS,
  SIGNIN_FORM_ERRORS,
  RESET_PASSWORD_FORM_ERROR,
  VIDEO_FORM_ERRORS,
  ADD_VIDEO_SUCCESS,
  ADD_EVENT_SUCCESS,
  EVENT_FORM_ERRORS,
  UPDATE_USER_SUCCESS,
  USER_EDIT_FORM_ERRORS,
  FORM_VIMEO_VIDEO_FAILURE,
  ADD_VIMEO_VIDEO_SUCCESS,
  UPDATE_VIMEO_VIDEO_SUCCESS,
  ADD_CATEGORY_SUCCESS,
  FORM_CATEGORIES_FAILURE,
  ADD_BAN_SUCCESS,
  FORM_BANS_FAILURE,
  ADD_SLIDE_SUCCESS,
  SLIDE_FORM_ERRORS,
  RENDER_CODES_SUCCESS,
  CODE_FORM_ERRORS,
  ACTIVATE_CODE_SUCCESS,
  USER_CODE_FORM_ERRORS,
  GALLERY_FORM_ERRORS,
  ADD_GALLERY_IMAGE_SUCCESS,
  GROUP_TICKET_FORM_ERRORS,
  UPDATE_GROUP_TICKET_CONFIG_SUCCESS,
  GIFT_FORM_ERRORS,
  UPDATE_GIFT_CONFIG_SUCCESS,
} from "../actions/_types";

export default formReducer.plugin({
  signUpForm: (state, action) => {
    switch (action.type) {
      case SIGNUP_FORM_ERRORS:
        return {
          ...state,
          syncErrors: action.payload,
        };
      default:
        return state;
    }
  },
  signInForm: (state, action) => {
    switch (action.type) {
      case SIGNIN_FORM_ERRORS:
        return {
          ...state,
          syncErrors: action.payload,
        };
      default:
        return state;
    }
  },
  resetPasswordForm: (state, action) => {
    switch (action.type) {
      case RESET_PASSWORD_FORM_ERROR:
        return {
          ...state,
          syncErrors: action.payload,
        };
      default:
        return state;
    }
  },
  videoForm: (state, action) => {
    switch (action.type) {
      case ADD_VIDEO_SUCCESS:
        return {
          ...state,
          registeredField: undefined,
        };
      case VIDEO_FORM_ERRORS:
        return {
          ...state,
          syncErrors: action.payload,
        };
      default:
        return state;
    }
  },
  slideForm: (state, action) => {
    switch (action.type) {
      case ADD_SLIDE_SUCCESS:
        return {
          ...state,
          registeredField: undefined,
        };
      case SLIDE_FORM_ERRORS:
        return {
          ...state,
          syncErrors: action.payload,
        };
      default:
        return state;
    }
  },
  categoryForm: (state, action) => {
    switch (action.type) {
      case ADD_CATEGORY_SUCCESS:
        return {
          ...state,
          registeredField: undefined,
        };
      case FORM_CATEGORIES_FAILURE:
        return {
          ...state,
          syncErrors: action.payload,
        };
      default:
        return state;
    }
  },
  galleryForm: (state, action) => {
    switch (action.type) {
      case ADD_GALLERY_IMAGE_SUCCESS:
        return {
          ...state,
          registeredField: undefined,
        };
      case GALLERY_FORM_ERRORS:
        return {
          ...state,
          syncErrors: action.payload,
        };
      default:
        return state;
    }
  },
  banForm: (state, action) => {
    switch (action.type) {
      case ADD_BAN_SUCCESS:
        return {
          ...state,
          registeredField: undefined,
        };
      case FORM_BANS_FAILURE:
        return {
          ...state,
          syncErrors: action.payload,
        };
      default:
        return state;
    }
  },
  eventForm: (state, action) => {
    switch (action.type) {
      case ADD_EVENT_SUCCESS:
        return {
          ...state,
          registeredField: undefined,
        };
      case EVENT_FORM_ERRORS:
        return {
          ...state,
          syncErrors: action.payload,
        };
      default:
        return state;
    }
  },
  vimeoVideoForm: (state, action) => {
    switch (action.type) {
      case ADD_VIMEO_VIDEO_SUCCESS:
      case UPDATE_VIMEO_VIDEO_SUCCESS:
        return {
          ...state,
          registeredField: undefined,
        };
      case FORM_VIMEO_VIDEO_FAILURE:
        return {
          ...state,
          syncErrors: action.payload,
        };
      default:
        return state;
    }
  },
  editUserForm: (state, action) => {
    switch (action.type) {
      case UPDATE_USER_SUCCESS:
        return {
          ...state,
          registeredField: undefined,
        };
      case USER_EDIT_FORM_ERRORS:
        return {
          ...state,
          syncErrors: action.payload,
        };
      default:
        return state;
    }
  },
  codeForm: (state, action) => {
    switch (action.type) {
      case RENDER_CODES_SUCCESS:
        return {
          ...state,
          registeredField: undefined,
        };
      case CODE_FORM_ERRORS:
        return {
          ...state,
          syncErrors: action.payload,
        };
      default:
        return state;
    }
  },
  userCodeForm: (state, action) => {
    switch (action.type) {
      case ACTIVATE_CODE_SUCCESS:
        return {
          ...state,
          registeredField: undefined,
        };
      case USER_CODE_FORM_ERRORS:
        return {
          ...state,
          syncErrors: action.payload,
        };
      default:
        return state;
    }
  },
  giftConfigForm: (state, action) => {
    switch (action.type) {
      case UPDATE_GIFT_CONFIG_SUCCESS:
        return {
          ...state,
          registeredField: undefined,
        };
      case GIFT_FORM_ERRORS:
        return {
          ...state,
          syncErrors: action.payload,
        };
      default:
        return state;
    }
  },
  groupTicketConfigForm: (state, action) => {
    switch (action.type) {
      case UPDATE_GROUP_TICKET_CONFIG_SUCCESS:
        return {
          ...state,
          registeredField: undefined,
        };
      case GROUP_TICKET_FORM_ERRORS:
        return {
          ...state,
          syncErrors: action.payload,
        };
      default:
        return state;
    }
  },
});
