import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Row } from "antd";
import {
  signUpUser,
  clearSignUp,
} from "../../../actions/authActions";
import { getConfig } from "../../../actions/appConfigActions";
import SignUpForm from "./SignUpForm";
import Wrapper from "../../wrappers/Wrapper";
import SingleButton from "../../form-components/SingleButton";
import { withTranslation } from "react-i18next";

class SignUp extends React.Component {
  onSubmit = (formValues) => {
    this.props.signUpUser(formValues);
  };
  moveToBottom = () => {
    document
      .querySelector(".invisible-scroll")
      .scrollIntoView({ block: "end", behavior: "smooth" });
  };
  componentDidMount() {
    this.props.getConfig(false);
  }
  componentWillUnmount() {
    this.props.clearSignUp();
  }
  render() {
    const {
      noauth_bg_image,
      regulations_page,
      policy_page,
    } = this.props.config;
    const { t } = this.props;
    return (
      <div ref={this.myRef}>
        <Wrapper
          title={t("signup.title")}
          bg={noauth_bg_image}
          authLayout={true}
        >
          <Row gutter={[10, 40]}>
            <Col span={12}>
              <Link to={`/logowanie`}>
                <SingleButton block>
                  {t("signup.have_account")}
                </SingleButton>
              </Link>
            </Col>
            <Col span={12}>
              <SingleButton
                block
                className="filled filled-disabled"
                onClick={() => this.moveToBottom()}
              >
                {t("signup.create_account")}
              </SingleButton>
            </Col>
          </Row>
          <SignUpForm
            signUpState={this.props.signUpState}
            regulationsPage={regulations_page}
            policyPage={policy_page}
            loadingConfig={this.props.loadingConfig}
            onSubmit={this.onSubmit}
            initialValues={{ country_code: "PL" }}
          />
          <div className="invisible-scroll" />
        </Wrapper>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    signUpState: state.auth.signUp,
    config: state.appConfig.config,
    loadingConfig: state.appConfig.loading,
  };
};

export default connect(mapStateToProps, {
  signUpUser,
  getConfig,
  clearSignUp,
})(withTranslation()(SignUp));
