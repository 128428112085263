import React from "react";
import { Layout, Card } from "antd";
import { connect } from "react-redux";
import {
  authFooterVariant,
  facebookPixel,
  multipleLanguages,
} from "../../theme-variables";
import MiniNavbar from "../navbars/MiniNavbar";
import MiniFooter from "../footers/MiniFooter";
import Navbar from "../navbars/Navbar";
import Footer from "../footers/Footer";
import CookiePopup from "./CookiePopup";
import teappLogo from "../../static/teapp_logo.svg";
import "./Wrapper.scss";
import { withTranslation } from "react-i18next";
import LanguageSelector from "../LanguageSelector";
import { getConfig } from "../../actions/appConfigActions";
import history from "../../history";
import Bars from "./Bars";
import ReactPixel from "react-facebook-pixel";

class Wrapper extends React.Component {
  componentDidMount() {
    this.props.getConfig(false);
  }
  componentDidUpdate() {
    const { config, auth } = this.props;
    if (
      config.maintenance &&
      window.location.pathname !== "/admin-login" &&
      window.location.pathname !== "/przerwa-techniczna"
    )
      if (!auth.user || auth.user.role !== "admin") {
        history.push("/przerwa-techniczna");
      }
  }
  render() {
    const {
      t,
      auth,
      title,
      subtitle,
      authLayout,
      children,
      fullWidth,
    } = this.props;
    const pathname =
      window.location.pathname.substr(1, window.location.pathname.length) ||
      "home";
    const pathnameClass = `path-${pathname}`;
    if (!auth.authenticated && !authLayout)
      return (
        <Layout
          className={`auth-layout ${
            fullWidth ? "full-width" : null
          } ${pathnameClass}`}
        >
          <Layout.Content>
            <Navbar />
            <div
              className={fullWidth ? "content-wrapper-full" : "content-wrapper"}
            >
              <div className="content">{children}</div>
              <Footer />
            </div>
            <CookiePopup />
            <Bars />
          </Layout.Content>
        </Layout>
      );
    else if (!auth.authenticated || authLayout)
      return (
        <>
          <Layout className={`noauth-layout ${pathnameClass}`}>
            <div
              className="fullsize-bg"
              style={{
                backgroundImage: this.props.bg ? `url(${this.props.bg})` : "",
              }}
            ></div>
            <Layout.Content>
              <Card bordered={false}>
                <div>
                  <MiniNavbar title={title} subtitle={subtitle} />
                  <div className="wrapper-content">
                    {children}
                    {multipleLanguages ? (
                      <div className="languageSwitcher">
                        <LanguageSelector />
                      </div>
                    ) : null}
                  </div>
                </div>
                <MiniFooter />
              </Card>
            </Layout.Content>
            <CookiePopup />
            <Bars />
          </Layout>
          {authFooterVariant === 2 ? (
            <a
              href="https://teapp.pl"
              target="_blank"
              rel="noopener noreferrer"
              className="mini-footer-variant-2"
            >
              <span>{t("footer.created")}:</span>
              <img src={teappLogo} alt="Logo Teapp" />
            </a>
          ) : null}
        </>
      );
    else
      return (
        <Layout
          className={`auth-layout ${
            fullWidth ? "full-width" : null
          } ${pathnameClass}`}
        >
          <Layout.Content>
            <Navbar />
            <div
              className={fullWidth ? "content-wrapper-full" : "content-wrapper"}
            >
              <div className="content">{children}</div>
              <Footer />
            </div>
            <CookiePopup />
            <Bars />
          </Layout.Content>
        </Layout>
      );
  }
}

const mapStateToProps = (state) => {
  return {
    config: state.appConfig.config,
    loading: state.appConfig.loading,
    auth: state.auth,
  };
};

export default connect(mapStateToProps, { getConfig })(
  withTranslation()(Wrapper)
);
