import {
  GET_EVENTS_SUCCESS,
  GET_EVENTS_FAILURE,
  EVENTS_LOADING,
  DELETE_EVENT_SUCCESS,
  DELETE_EVENT_FAILURE,
  ADD_EVENT_SUCCESS,
  ADD_EVENT_FAILURE,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_FAILURE,
  GET_EVENT_SUCCESS,
  GET_EVENT_FAILURE,
} from "../actions/_types";

const initialState = {
  events: [],
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_EVENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        events: action.payload,
      };
    case GET_EVENTS_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case GET_EVENT_SUCCESS:
      let found = false;
      let events = state.events.map((event) => {
        if (event.id === action.payload.id) {
          found = true;
          return action.payload;
        }
        return event;
      });
      if (!found) events.push(action.payload);
      return {
        ...state,
        loading: false,
        error: undefined,
        events: events,
      };
    case GET_EVENT_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case DELETE_EVENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: undefined,
        events: state.events.filter(
          (event) => event.id !== action.payload
        ),
      };
    case DELETE_EVENT_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
        time: Date.now(),
      };
    case ADD_EVENT_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: undefined,
        events: [action.payload, ...state.events],
      };
    case ADD_EVENT_FAILURE:
      return {
        ...state,
        success: false,
        error: action.payload,
        loading: false,
        time: Date.now(),
      };
    case UPDATE_EVENT_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        error: undefined,
        events: state.events.map((event) => {
          if (event.id === action.payload.id) return action.payload;
          return event;
        }),
      };
    case UPDATE_EVENT_FAILURE:
      return {
        ...state,
        success: false,
        error: action.payload,
        loading: false,
        time: Date.now(),
      };
    case EVENTS_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
